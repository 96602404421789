import React from 'react';
import { Heading, Box, Link } from 'rebass';
import Layout from '../components/Layout';

const NotFoundPage = () => (
  <Layout>
    <Box mt={[4, 5, 6]} mx={[3, 4, 6]} width={[320, 400, 700]}>
      <Heading color="font" fontSize={[6]} mb={[4]}>
        404 - there isn't anything here
      </Heading>
      <Heading color="secondary" fontSize={[5]}>
        <Link href={'/'}>click here to go to shivain.me</Link>
      </Heading>
    </Box>
  </Layout>
);

export default NotFoundPage;
